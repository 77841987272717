.icon_container a {
  visibility: hidden;
}
.icon_container:hover a {
  visibility: visible;
}
.rs-popover-content {
  display: flex;
  flex-direction: column;
}
