body {
  margin: 0;

  font-family: 'Roboto Slab', 'serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
textarea{
  font-family: 'Roboto Slab', 'serif' !important;
}

code {
  font-family: 'Roboto Slab', 'serif' !important
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 15px;
}
.infinite-scroll-component__outerdiv{
  width: 100%;
}

/* override datepicker css */
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  color:#023047
}
.react-datepicker__header{
  background-color:rgba(33, 158, 188, 0.4) !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
  border-color: #023047 !important;
}
.react-datepicker__year-dropdown{
  background-color:white !important
}
.react-datepicker__navigation{
  top:15px
}
.react-datepicker__header__dropdown{
  padding-top: 5px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
  border-bottom-color:rgba(33, 158, 188, 0.4) !important;
  background: white;

}

.react-datepicker__input-container input{
  width: -webkit-fill-available !important;
}