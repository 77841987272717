.background-holder {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.main-dashboard-container {
  overflow-x: hidden;
}

.navigation-item:hover {
  background-color: transparent;
}
.rs-navbar-default .rs-dropdown.rs-dropdown-open .rs-dropdown-toggle,
.rs-navbar-default .rs-dropdown .rs-dropdown-toggle:hover,
.rs-navbar-default .rs-navbar-item:hover {
  background-color: transparent;
}
a:active,
a:focus,
a:hover {
  text-decoration: none;
}

.dashboard-container-for-cards {
  padding-left: 4%;
  padding-right: 4%;
  max-width: 100vw;
}

.rs-nav-subtle.rs-nav-horizontal .rs-nav-bar {
  border-top: 0;
  border-top: 0;
}

.story-box-container {
  flex-direction: row;
  overflow-x: scroll;
  display: flex;
  flex-wrap: wrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.story-box-container::-webkit-scrollbar {
  display: none;
}
.information-personal-box {
  width: 100%;
  margin-left: 10px;
  border-radius: 5px;
  padding: 30px;
}
.information-container-button {
  width: 100%;
  border-radius: 5px;
  height: 50px;
}
.follow-card-container-dashboard {
  width: 100%;
  margin-left: 10px;
  border-radius: 5px;
}
