/* Default height */
.swiper {
  height: 150px; /* Default height */
}

@media (min-width: 640px) {
  .swiper {
    height: 200px; /* Height for screens wider than 640px */
  }
}

@media (min-width: 768px) {
  .swiper {
    height: 250px; /* Height for screens wider than 768px */
  }
}

@media (min-width: 1024px) {
  .swiper {
    height: 250px; /* Height for screens wider than 1024px */
  }
}

/* Custom styles for the Swiper navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  background-color: #023047; /* Change background color */
  color: #fff; /* Change text/icon color */
  width: 50px; /* Adjust the width */
  height: 50px; /* Adjust the height */
  border-radius: 50%; /* Make buttons round */
  opacity: 0.8; /* Adjust opacity */
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: #219ebc; /* Change background color on hover */
  opacity: 1; /* Make buttons fully opaque on hover */
}

/* Customize the next button */
.swiper-button-next::after {
  font-size: 20px; /* Adjust the icon size */

  font-weight: 700;
}

/* Customize the previous button */
.swiper-button-prev::after {
  font-size: 20px; /* Adjust the icon size */
}
